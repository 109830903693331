<template>
    <div>
        <b-overlay :show="show" spinner-variant="danger" spinner-type="grow" spinner-small rounded="sm">
            <b-card>
                <h4 class="font-weight-bold text-center mb-2">Active Coming Soons List</h4>
                <!-- draggable -->
                <draggable v-model="list" class="list-group list-group-flush cursor-move">
                    <transition-group type="transition" name="flip-list">
                        <b-list-group-item v-for="(listItem, index) in list" :key="listItem.loopId">
                            <div class="d-flex justify-content-between">
                                <div class="mt-50">
                                    <!-- <label class="font-weight-bolde text-danger">Position</label> -->
                                    <h4 class="font-weight-bolder text-danger mb-0 ml-2">#{{ index + 1 }}</h4>
                                </div>

                                <div class="col-md-2">
                                    <label class="font-weight-bolder text-danger">Name</label>
                                    <b-card-text class="font-weight-bold mb-0">
                                        {{ listItem.name }}
                                    </b-card-text>
                                </div>

                                <div>
                                    <label class="font-weight-bolder text-danger">Type</label>
                                    <b-card-text class="font-weight-bold mb-0">
                                        {{ listItem.coming_soonable_type }}
                                    </b-card-text>
                                </div>
                                <b-media v-if="listItem.banner_thumbnail !== null" vertical-align="center" class="mt-50 mr-25">
                                    <template #aside>
                                        <b-img v-bind="mainProps" :src="listItem.banner_thumbnail || listItem.thumbnail" />
                                    </template>
                                </b-media>
                                <b-avatar v-else :text="listItem.name.slice(0, 2)" class="mr-1 mt-50" />
                            </div>
                            <b-row class="justify-content-end mt-1 mr-50">
                                <b-button @click="editBtn(index)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="">
                                    <feather-icon icon="EditIcon" />
                                </b-button>
                                <b-button @click="deleteBtn(index)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle">
                                    <feather-icon icon="Trash2Icon" />
                                </b-button>
                            </b-row>
                            <b-row class="justify-content-center" v-if="listItem.editStage == true">
                                <b-col cols="6">
                                    <b-form-group>
                                        <v-select v-model="slideShowValue" :options="slideShowOption" label="name" input-id="type" placeholder="Choose Your SlideShow" :loading="isLoading" @search="filterMusic" @input="changeSlideshow(index, listItem.position)" :reduce="(val) => val" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-list-group-item>
                    </transition-group>
                </draggable>
                <b-row class="justify-content-center my-2">
                    <b-button @click="plusSlideShow = !plusSlideShow" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="danger" class="btn-icon rounded-circle">
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-row>
                <b-row class="justify-content-center" v-if="plusSlideShow">
                    <b-col cols="6">
                        <b-form-group class="mt-1">
                            <v-select v-model="slideShowValue" :options="slideShowOption" label="name" input-id="type" placeholder="Choose Your SlideShow" :loading="isLoading" @search="filterMusic" :reduce="(val) => val" />
                        </b-form-group>
                    </b-col>
                    <b-button @click="addSlideshow()" variant="flat-success" class="btn-icon"> Add </b-button>
                </b-row>
                <b-row class="justify-content-end mt-3 mr-2">
                    <b-button @click="submitSlideShow" variant="primary" class="btn-icon"> Finalize </b-button>
                </b-row>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Ripple from "vue-ripple-directive";
import { BImg, BOverlay, BCard, BCardText, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BListGroupItem, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import { makeid } from "@/utils/math";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import useSlideShowList from "./useSlideShowList";
import userStoreModule from "../../user/userStoreModule";
import SlideshowRepository from "@/abstraction/repository/slideShowRepository";
const RANDOM_TOKEN = makeid(50);
export default {
    directives: {
        Ripple,
    },
    components: {
        BImg,
        BOverlay,
        BListGroupItem,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        Prism,
        vSelect,
        draggable,
        BFormGroup,
    },
    setup() {
        const { fetchActiveSlideShows, storeActiveSlideShows } = useSlideShowList();
        const show = ref(true);
        const isLoading = ref(false);
        const slideShowValue = ref(undefined);
        const list = ref([]);
        const slideShowRepository = new SlideshowRepository();
        const plusSlideShow = ref(false);
        const slideShowOption = ref(undefined);
        const mainProps = ref({
            width: 110,
            height: 75,
        });
        const fetchSlideshows = async (param = {}) => {
            const resource = await slideShowRepository.comingSoonIndex(param);
            slideShowOption.value = resource.data;
        };
        onUnmounted(() => {});

        onMounted(() => {
            fetchSlideshows();
            fetchActiveSlideShows().then((res) => {
                for (let index = 0; index < res.data.data.length; index++) {
                    Object.assign(res.data.data[index], { editStage: false });
                    Object.assign(res.data.data[index], { loopId: makeid(20) });
                }
                list.value = res.data.data;
                show.value = false;
                console.log(list.value);
            });
        });
        const editBtn = (index) => {
            show.value = true;
            list.value[index].editStage = !list.value[index].editStage;
            show.value = false;
        };
        const deleteBtn = (index) => {
            list.value.splice(index, 1);
        };
        const changeSlideshow = (index, positionVal) => {
            console.log(list.value[index]);
            Object.assign(slideShowValue.value, { position: positionVal, loopId: makeid(20) });
            console.log(slideShowValue.value);
            list.value[index] = slideShowValue.value;
        };

        const addSlideshow = (index) => {
            Object.assign(slideShowValue.value, { position: list.value[slideShowValue.value], loopId: makeid(20) });
            list.value.push(slideShowValue.value);
        };
        const submitSlideShow = () => {
            for (let i = 0; i < list.value.length; i++) {
                list.value[i].position = i + 1;
            }
            console.log(list.value);
            let data = { coming_soons: list.value };
            storeActiveSlideShows(data).then((res) => {
                console.log(res);
            });
        };
        const filterMusic = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchSlideshows(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };

        return {
            fetchActiveSlideShows,
            mainProps,
            avatarText,
            list,
            slideShowOption,
            filterMusic,
            fetchSlideshows,
            isLoading,
            changeSlideshow,
            slideShowValue,
            plusSlideShow,
            addSlideshow,
            submitSlideShow,
            show,
            editBtn,
            deleteBtn,
            // editStage,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
.list-group-item {
    transition: all 1s;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
